<template>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
            <v-carousel>
                <v-carousel-item
                v-for="(item,i) in items"
                :key="i"
                :src="item.src"
                reverse-transition="fade-transition"
                transition="fade-transition"
                ></v-carousel-item>
            </v-carousel>
        </v-col>
        <v-col
          v-for="card in applications"
          :key="card.note5"
          xs="12" 
          sm="5" 
          md="3"
        >
          <v-card>
            <v-img
              :src="card.url_image"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="card.note5"></v-card-title>
            </v-img>

            <v-card-actions>
                <v-btn
                    color="orange lighten-2"
                    text
                >
                    <router-link :to="{path:card.link_web}">
                    Explore
                    </router-link>
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
  export default {
    data: () => ({
        items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          
        ],
        cards: [
            { title: 'MILL Production', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 3, link: '/admin/kka/production' },
            { title: 'Accounting', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 3, link: '/admin/kka/production' },
            { title: 'Coil Center', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3, link: '/admin/kka/production' },
            { title: 'Faktur Payment', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3, link: '/admin/kka/production' }
        ],
        applications: []
    }),
    mounted(){
        this.getApplication()
    },
    methods:{
        getApplication() {   
            this.loading = true
            axios.get(`${process.env.VUE_APP_URL}/api/get_appl?entity_id=KKA`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data.data
            });
        },
    },
  }
</script>